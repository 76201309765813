
body{
  font-family: Inter, sans-serif;
}

/*****************************  LOGIN *****************************/
#Login {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
  font-family: Inter, sans-serif;
  height: 100vh;
  overflow: hidden;
  background: linear-gradient(98deg, rgb(82, 119, 144), rgb(23, 24, 71) 94%);
}

#Login .container {
  display: flex;
  align-items: center;
  justify-content: center;
  min-height: 100vh;
}

#Login .screen {
  background: #3f406a;
  position: relative;
  height: 600px;
  width: 360px;
  box-shadow: 0px 0px 24px #3f406a;
}

#Login .screen__content {
  z-index: 1;
  position: relative;
  height: 100%;
}

#Login .screen__background {
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  z-index: 0;
  -webkit-clip-path: inset(0 0 0 0);
  clip-path: inset(0 0 0 0);
}

#Login .screen__background__shape {
  transform: rotate(45deg);
  position: absolute;
}

#Login .screen__background__shape1 {
  height: 520px;
  width: 520px;
  background: #FFF;
  top: -50px;
  right: 120px;
  border-radius: 0 72px 0 0;
}

#Login .screen__background__shape2 {
  height: 220px;
  width: 220px;
  background: linear-gradient(98deg, rgb(82, 119, 144), rgb(23, 24, 71) 94%);
  top: -172px;
  right: 0;
  border-radius: 32px;
}

#Login .screen__background__shape3 {
  height: 540px;
  width: 190px;
  background: linear-gradient(98deg, rgb(82, 119, 144), rgb(23, 24, 71) 94%);
  top: -24px;
  right: 0;
  border-radius: 32px;
}

#Login .screen__background__shape4 {
  height: 400px;
  width: 200px;
  background: linear-gradient(98deg, rgb(82, 119, 144), rgb(23, 24, 71) 94%);
  top: 420px;
  right: 50px;
  border-radius: 60px;
}

#Login .login_form {
  width: 320px;
  padding: 30px;
  padding-top: 116px;
}

#Login .logo {
  width: 170px;
}

#Login .login__field {
  padding: 20px 0px;
  position: relative;
}

#Login svg {
  width: 1.4rem;
  height: 1.4rem;
  color: #002856;
}

#Login .login__input {
  border: none;
  border-bottom: 2px solid #D1D1D4;
  background: none;
  padding: 10px;
  padding-left: 26px;
  font-weight: 700;
  width: 75%;
  transition: .2s;
}

#Login .login__input:active,
#Login .login__input:focus,
#Login .login__input:hover {
  outline: none;
  border-bottom-color: #002856;
}

#Login .login__submit {
  background: #fff;
  font-size: 14px;
  margin-top: 30px;
  padding: 16px 20px;
  border-radius: 26px;
  border: 1px solid #002856;
  text-transform: uppercase;
  font-weight: 700;
  display: flex;
  align-items: center;
  width: 100%;
  color: #002856;
  box-shadow: 0px 2px 2px #002856;
  cursor: pointer;
  transition: .2s;
}

#Login .login__submit:active,
#Login .login__submit:focus,
#Login .login__submit:hover {
  border-color: #6A679E;
  outline: none;
}

#Login .button__icon {
  font-size: 24px;
  margin-left: auto;
  color: #7875B5;
}

/*****************************  LOGIN END *****************************/
#Screens{
  color: rgba(58, 53, 65, 0.87);
}

.bg-primary-blue{
  /* background-color: #1976d2; */
  /* background-color: #5d54a4; */
  /* background-color: rgb(23, 24, 71); */
  background-color: #002856;
}
.text-primary-blue{
  /* color: #1976d2; */
  /* color: #5d54a4; */
  /* color: rgb(23, 24, 71); */
  color: #002856;
}
.border-primary-blue {
  border: 1px solid;
  /* border-color: #1976d2; */
  /* border-color: #5d54a4; */
  /* border-color: rgb(23, 24, 71); */
  border-color: #002856;
}

#Routes input:focus,
#Routes textarea:focus,
#Routes select:focus,
#Routes .css-t3ipsp-control, 
#Routes .search-input:focus {
  outline: 0;
  box-sizing: border-box;
  /* box-shadow: 0 0 0 1px #5d54a4;
  border: solid 1px #5d54a4 !important; */
  box-shadow: 0 0 0 1px rgb(23, 24, 71);
  border: solid 1px rgb(23, 24, 71) !important;
}
#Routes .css-t3ipsp-control input {
  border: 0 !important;
  box-shadow: none !important;
}
#Routes input,
#Routes textarea,
#Routes select,
#Routes option{
  color: rgb(56, 56, 56, 0.85) !important;
}

#SideTabMenu a {
  text-decoration: none;
}

.active-nav > div {
  /* background-color: #1976d2; */
  /* background-color: #5d54a4; */
  color: #FFF; 
  border-radius: 0 31px 31px 0;
  box-shadow: 0px 4px 8px -4px rgba(58, 53, 65, 0.42);
  background-image: linear-gradient(98deg, rgb(82, 119, 144), rgb(23, 24, 71) 94%);
  /* background-image: linear-gradient(98deg, #7191e3, rgb(23, 24, 71) 94%); */
}

.sidenav{
  box-shadow: rgba(0, 0, 0, 0.15) 5px 2px 5px;
}

.sidenav, .sidenav-btn {
  /* background: #1b1b1b; */
  /* color: silver; */
  background: #F4F5FA;
  color: rgba(58, 53, 65, 0.87);
}

.sidenav-item .nav-icon {
  margin: 0 22px 0 7px;
}

.sidenav-item .nav-text{
  font-size: 16px;
  margin-bottom: 1.5px;
  white-space: nowrap;
}

.project-logo div{
  align-items: end;
  margin-left: 5.5px;
  font-family: Lato, sans-serif;
}

.project-logo span{
  /* letter-spacing: 13px; */
  letter-spacing: 8px;
  font-style: italic;
}

.project-logo span{
  font-size: 2.2em;
}

.project-logo span:first-child{
  font-size: 3.2em;
  line-height: 1;
}

#company-change-button,
#fleet-change-button{
  margin-left: 44px;
}

.flex-form-cell{
  flex: 1;
}

.flex-form-cell.medium{
  flex: 2;
}

.flex-form-cell.large{
  flex: 4;
}

.flex-form-cell.extra-large{
  flex: 5;
}

.list-view-container .react-table-lite-table thead th {
  background-color: rgb(249, 250, 252);
  color: rgba(58, 53, 65, 0.87);
  text-transform: capitalize;
  border: 0px !important;
  line-height: 40px;
  font-size: 0.98em;
  user-select: none;
}

.react-table-lite-table thead {
  box-shadow: 0 10px 15px -3px rgb(0 0 0 / 0.1), 0 4px 6px -4px rgb(0 0 0 / 0.1);
}
.react-table-lite-header span > span {
  margin-left: 2px;
  font-size: 0.75em;
}

.react-table-lite-row td{
  border: 0px !important;
  max-width: 370px;
  padding: 6px;
}

.react-table-lite-table .approved-row td {
  background: #002856;
  color: #fff;
}
.approved-row td svg{
  color: white !important;
}

.react-table-lite-row td{
  border-bottom: 1px solid #dadadaa8 !important;
}

/* .request-form-container {
  overflow: hidden;
} */

.request-form-container,
.request-form-container .section {
  padding-top: 2.2em;
}

.request-form-container .heading {
  position: absolute;
  background-color: #FFF;
  font-size: 24px;
  padding: 0 7px;
  left: 9px;
  top: 18px;
}

.request-form-container .heading.heading-small-size {
  font-size: 18px;
  line-height: 22px;
  top: 24px;
}

.add-service-button {
  border-style: dashed !important;
  border: 1px solid #ccc;
  background: none;
  cursor: pointer;
}

.request-leg-newline-border{
  border: 0;
  border-bottom: 2px dotted gray;
}

.request-leg-newline-border > div{
  top: -9px;
  padding-right: 10px;
}

.request-form-container .request-legs-container .w3-col{
  background: #f2f2f2;
}

.w3-row-padding {
  display: flex;
  flex-wrap: wrap;
}
.w3-row-padding:before,
.w3-row-padding:after {
  display: none;
}


.request-block {
  padding: 20px 0;
}

.custom-table {
  width: 100%;
  margin: 0 0 15px;
  border-collapse: collapse;
  border-spacing: 0;
}
.custom-table td {
  border: 2px solid #d6d5d5;
  padding: 5px 15px;
  text-align: center;
}
.custom-table .title {
  background: #f4f2f2;
  color: #000;
  font-weight: 700;
}
.custom-table .code {
  display: block;
}
.custom-table .tag-text {
  /* background: #3d3d3d; */
  /* color: #edb219; */
  background-color: #f4f2f2;
  color: #5d54a4;
  padding: 0 4px;
}

.table-heading {
  display: block;
  border: 2px solid #d6d5d5;
  /* background: #3d3d3d; */
  /* color: #edb219; */
  background-color: #f4f2f2;
  color: #5d54a4;
  text-transform: uppercase;
  padding: 10px;
  text-align: center;
  font-weight: 700;
  letter-spacing: 1px;
  margin: 0 0 15px;;
}

.catering-detail {
  text-align: left;
  display: flex;
}

.catering-detail strong {
  font-weight: 700;
  background: #f4f2f2;
  padding: 4px 5px;
}

.catering-detail .text {
  flex-grow: 1;
  flex-basis: 0;
  min-width: 0;
  border: 1px solid #d6d5d5;
  border-left: 2px solid #d6d5d5;
  padding: 4px 5px;
}

.catering-detail:first-child .text {
  border-top: none;
}

.request-form-section-heading {
  display: flex;
  padding: 10px 5px;
  color: rgb(23, 24, 71);
  background-color: rgb(224, 225, 225);
  border: 2px solid #d6d5d5; 
}

.react-table-lite-pagination-container svg {
  color: #424242;
}

.react-table-lite-pagination-item{
  color: #424242;
  padding: 6px 5px !important;
  min-width: 26px;
  cursor: pointer;
  text-align: center;
}
.react-table-lite-pagination-item:hover{
  background-color: gray;
  color:#000;
}

.react-table-lite-pagination-active-item {
  background: #002856;
  color: #FFF;
}

.react-table-lite-table tbody tr:nth-child(even) {
  background-color: #f1f1f1;
}

.react-table-lite-table th {
  padding: 6px;
}
.react-table-lite-action-btn-container{
  display: flex;
}
.react-table-lite-action-btn-container button,
.custom-view-fsr-btn,
.custom-edit-fsr-btn,
.custom-delete-fsr-btn{
  border: 0;
  background: none;
  cursor: pointer;
}

.tooltip-text {
  bottom: 20px;
  position: absolute;
  display: flex;
  flex-wrap: wrap;
  font-size: 0.9em;
  padding: 2px 1px;
  display: none !important;
}

.pdf-download-button{
  margin: 10px 20px;
  padding: 0.75em !important;
}

#Fsr-Legs {
  flex-direction: column;
  gap: 10px;
  /* overflow: auto; */
}

.list-request {
  margin: 0;
  padding: 0;
  list-style: none;
  display: flex;
  flex-direction: row;
  align-items: flex-end;
  /* flex-wrap: wrap; */
  min-width: 900px;
  gap: 5px;
}

.request-pagination {
  display: flex;
  flex-direction: row;
}

.request-pagination__item {
  display: flex;
  flex-direction: row;
  align-items: center;
  position: relative;
  background: #F4F5FA;
  font-weight: 700;
  padding: 20px 20px 20px 60px;
  gap: 10px;
}

.request-pagination__item:first-child {
  padding-left: 20px;
}

/* .request-pagination__item:hover, */
/* .request-pagination__item:hover:after, */
.request-pagination__item.active,
.request-pagination__item.active:after {
  background: #002856;
  color: #fff;
}

.request-pagination__item:after {
  position: absolute;
  left: 100%;
  top: 9px;
  content: '';
  width: 45px;
  height: 46px;
  background: #F4F5FA;
  margin: 0 0 0 -23px;
  transform: rotate(45deg);
  z-index: 2;
}

.request-pagination__num {
  width: 24px;
  min-width: 24px;
  height: 24px;
  border-radius: 100%;
  border: 1px solid #000;
  font-size: 16px;
  line-height: 20px;
  background: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  color: #000;
}

.request-pagination__wrap {
  position: relative;
  z-index: 4;
}

.img-logo {
  width: 100%;
  height: auto;
  display: block;
}

.services-option-container{
  max-height: 250px;
  width: 100%;
  padding: 15px;
}
.services-option-container.pt-0{
  padding-top: 0;
}
.services-option-container.pt-0 .sticky.top-0.w3-white{
  background: #fff;
  z-index: 3;
}
#Routes .services-option-container.pt-0 .w3-input {
  padding: 15px 10px;
  outline: none !important;
  box-shadow: none !important;
  border-width: 0 0 1px !important;
}
.service-option {
  margin: 5px 0;
}
.service-option .w3-input {
  box-shadow: none;
  outline: none;
  border: 1px solid rgba(58, 53, 65, 0.4);
}
.service-option input[type=number]::-webkit-inner-spin-button, 
.service-option input[type=number]::-webkit-outer-spin-button { 
  -webkit-appearance: none; 
  margin: 0; 
}

@media (max-width: 991px) {
  .request-pagination__item {
    padding: 10px;
    font-size: 12px;
    line-height: 15px;
  }

  .request-pagination__item:first-child {
    padding-left: 10px;
  }

  .request-pagination__item:after {
    width: 30px;
    height: 30px;
    margin: -22px 0 0 -15px;
    left: 50%;
    top: 100%;
    opacity: 0;
    visibility: hidden;
  }

  .request-pagination__item.active:after {
    opacity: 1;
    visibility: visible;
  }
}

.w-custom-modal {
  max-width: 93vw;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.request-modal-body {
  flex-grow: 1;
  flex-basis: 0;
  min-height: 0;
  overflow: auto;
}

.site-switcher {
  position: relative;
}

.site-switcher input[type="checkbox"]{
  position: absolute;
  opacity: 0;
  visibility: hidden;
}

.site-switcher input[type="checkbox"]:checked + .fake-switcher {
  background: #002856;
}

.site-switcher input[type="checkbox"]:checked + .fake-switcher:before {
  background: #002856;
  left: 16px;
}

.site-switcher .fake-switcher {
  width: 36px;
  height: 10px;
  background: #d1d2d5;
  display: block;
  border-radius: 8px;
  transition: all 0.4s ease;
}

.site-switcher .fake-switcher:before {
  position: absolute;
  left: 0;
  width: 20px;
  height: 20px;
  border-radius: 100%;
  content: '';
  background: #d1d2d5;
  top: 50%;
  transition: all 0.4s ease;
  transform: translate(0, -50%);
}
@media (min-width: 768px) {
  .w3-custom-half {
    width: 49.99%;
  }
}

@media (max-width: 767px) {
  .request-pagination__item {
    flex-direction: column;
  }
  .react-table-lite-table thead th{
    line-height: 20px !important;
  }
  #company-change-button,
  #fleet-change-button{
    font-size: 0.8em;
  }
  #Session-Remaining .w3-medium{
    font-size: 0.75rem !important;
  }
  #Session-Remaining svg{
    width: 23px;
    height: 23px;
  }
  .zulu-time{
    display: none;
  }
}

.table-cell-middle{
  vertical-align: middle !important;
}

/* disabled react-select-field */
.css-16xfy0z-control {
  background-color: white !important;
  cursor: not-allowed !important;
  opacity: 0.3;
}

.css-16xfy0z-control :hover {
  cursor: not-allowed !important;
  opacity: 0.3;
}

.css-16xfy0z-control .css-1hb7zxy-IndicatorsContainer {
  opacity: 0;
}

.list-details {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-details li {
  margin: 0 0 10px;
}

.p-x-16 {
  padding-left: 16px;
  padding-right: 16px;
}

.align-top td {
  vertical-align: top;
}

.list-services {
  margin: 0;
  padding: 0;
  list-style: none;
}

.list-services li {
  margin: 0 0 10px;
  position: relative;
}

.list-services li:before {
  display: inline-block;
  vertical-align: top;
  margin: 9px 5px 0 0;
  width: 4px;
  height: 4px;
  border-radius: 100%;
  background: currentColor;
  content: '';
}

.list-services.no-bullets li {
  margin: 0;
}

.list-services.no-bullets li:before {
  display: none;
}

.hotel-service-box {
  margin: 0 0 15px;
  position: relative;
  overflow: hidden;
}

.hotel-service-title {
  display: block;
}

.hotel-service-alert {
  display: block;
  margin: 0 0 10px;
}

.custom-data-table td{
  font-size: 0.8em;
  border: 0px !important;
  border-bottom: 1px solid #dadadaa8 !important;
}

.custom-data-table td > div{
  padding: 6px;
}

.report-watermark {
  opacity: 0.2;
  font-size: 200px;
  position: absolute;
  transform: translate(-50%, -50%);
}

.text-success {
  color: #28a745 !important;
}

.relative .text-info {
  display: block;
  font-size: 12px;
  line-height: 15px;
  text-align: left;
  width: 100%;
  margin: -8px 0 4px -6px;
}

.table-heading {
  position: relative;
}

.fsrdetails-id {
  position: absolute;
  left: 15px;
  top: 15px;
}

.img-svg-plane {
  width: 16px;
  height: auto;
  display: inline-block;
  vertical-align: middle;
  margin: 0 5px 0 0;
}

.w3-categories:nth-child(2) .img-svg-plane {
  transform: rotate(90deg);
}

.invoice-top-area {
  flex-wrap: wrap;
}

.gap-10 {
  gap: 10px;
}

.gap-20 {
  gap: 20px;
}

.cost-remove-btn-holder {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
}

.cost-grids .field {
  width: calc(25% - 20px);
}

.cost-grids .field .w3-input {
  width: 100%;
}

.w3-filtered-area .mx-n1 {
  margin-left: -4px;
  margin-right: -4px;
}

.w3-filtered-area .chevron-holder {
  width: 20px;
}

.w3-filtered-area .chevron-holder svg {
  transform: rotate(90deg);
  display: block;
  transition: all 0.4s ease;
}

.w3-filtered-area .active .chevron-holder svg {
  transform: rotate(-90deg);
}

.w3-light {
  background: #f1f1f1;
}

.filter-tabs-list {
  margin: 0;
  padding: 0;
  list-style: none;
  gap: 10px;
  display: flex;
}

.filter-tabs-list button:not(.active) {
  background: rgba(82, 119, 144, 0.6);
}

.btn-changes {
  position: absolute;
  right: 15px;
  top: 10px;
}

.history-heading {
  margin: 0;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
}

.log-modal-header {
  margin: 0 0 20px;
}

.log-history-list li {
  position: relative;
  padding: 0 0 0 50px;
  min-height: 40px;
  margin: 0 0 15px;
}

.log-history-list li:before {
  position: absolute;
  left: 20px;
  top: 100%;
  height: 20px;
  background: #002856;
  width: 2px;
  margin: 0 0 0 -1px;
  content: '';
}

.log-history-list li:last-child:before {
  display: none;
}

.log-history-list .history-icon-holder {
  width: 40px;
  height: 40px;
  border-radius: 100%;
  position: absolute;
  left: 0;
  top: 0;
  background: #002856;
  color: #fff;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 6px;
}

.log-history-list .heading {
  font-weight: 700;
  font-size: 16px;
}

.fsr-changes-info {
  display: block;
  margin: 0 0 10px;
}

.request-history-changes-compare-table {
  width: 100%;
  border-collapse: collapse;
  border: none;
}

.request-history-changes-compare-table thead {
  text-align: left;
}

.request-history-changes-compare-table thead th {
  border: 1px solid #dadada;
  padding: 10px;
  background: #dadadaa8;
}

.request-history-changes-compare-table tbody td {
  padding: 10px;
  border: 1px solid #dadada;
}

.fsr-changes-sub-heading {
  display: block;
  margin: 0 0 15px;
}

.legs-listing {
  margin: 0;
  padding: 0 0 0 20px;
}

.changes-block {
  padding: 20px 0 0;
}

.py-1.w3-border-bottom {
  padding-bottom: 20px;
  padding-top: 10;
}

.fsr-changes-sub-heading {
  padding: 10px 0 0;
}

.bulk-invoice-container{
  height: calc(100vh - 100px);
  display: flex;
  text-align: center;
  align-items: center;
  justify-content: center;
} 

.rcs-digital-parent-custom,
.rcs-digital-picker-custom{
  background-color: transparent !important;
  width: 85px;
  margin-left: 2px;
  margin-right: 2px;
}

#invoice-report-filter-container .css-16xfy0z-control{
  background-color: hsl(0, 0%, 100%) !important;
  border-color: hsl(0, 0%, 80%) !important;
  cursor: no-drop;
  opacity: 1;
}

#invoice-report-selection-list li{
  line-height: 2.5em;
}
#invoice-report-selection-list li:hover{
   color: #002856;
}

.cost-plus-invoice-view-table {
  width: 100%;
}
.cost-plus-invoice-view-table .invoice-heading{
  padding: 20px;
  color: #5d54a4;
  background: #f4f2f2;
}

.cost-plus-invoice-item-cell-300 {
  max-width: 300px;
}

.cost-plus-invoice-item-cell-100 {
  max-width: 100px;
}

.cost-plus-invoice-item-cell-80 {
  max-width: 80px;
}

.temp-cost-plus-invoice-item{
  background-color: #f9f4d2;
  color: #280b77;
}

.cost-invoice-service-name{
  min-width: 300px;
}

.loaded-version td{
  color: #5e5f97;
}

.aircraft-filter-select-container .css-13cymwt-control{
  border-radius: 0;
}

.fsr-filters-container ul>li{
  padding: 4px 2px;
  cursor: pointer;
}

.fsr-filters-container ul>li:hover{
  background-color: #CCC;
  color: #000;
}

.fsr-filters-container .w3-dropdown-content{
  height: 180px;
  overflow-y: auto;
}

.aircraft-section-header{
  margin: 12px 0;
  display: flex; 
  justify-content: space-between;
}

.invoice-report-preview-hidden-section{
  display: none;
}

.taxes-summary-section{
  padding: 12px 0;
}

#letter-head-content-input{
  min-height: 230px;
  white-space: pre-wrap;
}

#letter-head-content-preview{
  display: none;
}

.invoice-report-page-2-footer{ 
  display: none;
}

.aircraft-wise-section .total-amount{
  text-align: right !important;
}

.ar .aircraft-wise-section .total-amount{
  text-align: left !important;
}
.ar{
  direction: rtl;
}
.ar, .ar table, .ar td, .ar th, .ar p{
  font-family:  sans-serif !important;
}

#language-select{
  font-family:  sans-serif !important;
}

.block-hour-date-time-input{
  border: 0px;
  border-bottom: 1px solid #ccc;
}

#Routes .block-hour-date-time-input:focus{  
  outline: none;
  border-top: 0px !important;
  border-left: 0px !important;
  border-right: 0px !important;
  box-shadow: none !important;
}

.bills-list-tooltip{
  bottom: 21px;
  width: 13em;
  padding: 4px;
  font-size: 0.89em !important;
}

#bills-data {
  margin-top: 20px;
  font-size: 0.89em;
}
.flex-heading{
  display: flex !important;
}